import React from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { NoData } from 'components/Errors/NoData';
import { BarCard } from '../Cards';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import styles from '../styles.scss';
import { currencyCell, ReactTable, unitCell } from 'components/ReactTable';
import tableStyles from 'components/ReactTable/styles.scss';


export const key = 'reports';
const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const browserLocale = navigator.language || 'en-US';
console.log('browserTimeZone', browserTimeZone);
console.log('browserLocale', browserLocale);

const MovementsTable = ({ movements, currency }) => {
	const augmentedMovements = movements.map(movement => ({ ...movement, currency: currency }));
	const columns = [
		{
			width: 25,
			Cell: ({ row }) => {
				const data = row._original;
				if (data.original_quantity || data.original_delivery_date) {
					return (<span className={tableStyles.Bullet} style={{ backgroundColor: '#02A7FF' }} />)
				}
				return null
			}
		},
		{
			Header: 'Number',
			accessor: 'number'
		},
		{
			Header: 'Name',
			accessor: 'name'
		},
		{
			Header: 'Quantity',
			accessor: 'quantity',
			Cell: unitCell,
			minWidth: 75
		},
		{
			Header: 'Order Date',
			accessor: 'order_date',
			align: 'center',
			width: 85,
			Cell: ({ value }) => new Date(value).toISOString().split('T')[0]
		},
		{
			Header: 'Total Savings',
			id: 'pureSavings',
			accessor: row => row.pure_savings,
			align: 'right',
			Cell: currencyCell
		},
		{
			Header: 'Inventory Savings',
			id: 'inventorySavings',
			accessor: row => row.inventory_savings,
			align: 'right',
			Cell: currencyCell
		},
		{
			Header: 'Order Savings',
			id: 'orderSavings',
			accessor: row => row.order_savings,
			align: 'right',
			Cell: currencyCell
		},
		{
			Header: 'Quantity Discounts',
			id: 'quantityDiscounts',
			accessor: row => row.quantity_discounts,
			align: 'right',
			Cell: currencyCell
		},
		{
			Header: 'Approved By',
			accessor: 'approvedBy_email'
		},
		{
			Header: 'Approved on',
			accessor: 'timestamp',
			align: 'center',
			Cell: ({ value }) => {
				const utcTimestamp = value.endsWith('Z') ? value : value + 'Z';
				console.log('utcTimestamp', utcTimestamp);
				return new Date(utcTimestamp).toLocaleString(browserLocale);
			}
		}

	];

	return (
		<div className="mt-8">
			<ReactTable
				columns={columns}
				getTrProps={(state, row) => ({
					to: `/reports/value-tracker/${row.original.id}`
				})}
				data={augmentedMovements}
				pageText={<>{movements.length} Movements &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Page</>}
				defaultSorted={[
					{
						id: 'timestamp',
						desc: true
					}
				]}
			/>
		</div>
	);
};


export function ValueTracker() {
	const { report } = useLoaderData();
	console.log('data in valuetracker', report);
	console.log('savings in report', report.savings);

	document.title = 'Value Tracker - GenLots';
	const [searchParams] = useSearchParams();
	const company = searchParams.get('company');

	if (!report.savings) {
		return (
			<NoData
				title={'Value tracker'}
				message={'There are no results here as you probably don\'t have value tracking enabled.'}
			/>
		);
	}
	const formatValue = (value) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: report.currency || 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	// Create savings breakdown data for donut chart
	const savingsBreakdown = {
		'Inventory': report.savings.inventory_savings,
		'Order': report.savings.order_savings,
		'Quantity': report.savings.quantity_discounts,
		'Scrap': report.savings.scrap_savings,
		'Total': report.savings.pure_savings
	};


	return (
		<div className={styles.ReportsPage}>
			<div className={styles.Header2}>
				<div className={styles.Title}>Value tracker</div>
			</div>
			<div className={styles.Body}>
				<Grid container alignItems="flex-start" spacing={5}>
					<Grid item xs={12} lg={6}>
						<BarCard
							title="Realized Savings"
							data={savingsBreakdown}
							containerId="savings-breakdown-chart"
							formatValue={formatValue}
							valueLabel=""
						/>
					</Grid>
					<Grid container justifyContent="flex-end" style={{ paddingRight: '15px' }}>
						<Grid item>
							<Button component="a"
									href={`${process.env.API_URL}/company/${company || 'me'}/reports/value-tracker?format=excel`}
									startIcon={<GetAppIcon />}>
								Download
							</Button>
						</Grid>
					</Grid>
					<Grid item sm={12}>
						<MovementsTable movements={report.movements} currency={report.currency} />
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

